.my-qr-code-cell {
  opacity: 1; /*0.8;*/
}

.my-qr-code-cell-empty {
  fill: white; /*lightgray;*/
}

.my-qr-code-cell-filled {
  fill: black; /*brown;*/
}

.size_qr {
  /* width: 30vh;*/
  /* width: 50vh;*/
  /* width: 30vh;*/
  background: white;
}

.sie_qr {
  /* width: 30vh;*/
  /* width: 50vh;*/
  /* width: 30vh;*/
  background: red;
}
